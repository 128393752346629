"use client"

import { Disclosure } from "@headlessui/react"
import { ChevronUpIcon } from "@heroicons/react/20/solid"

type Props = {
    question: string
    answer: string
}

export default function FaqDisclosure(props: Props) {
    return (
        <Disclosure>
            {({ open }) => (
                <>
                    <Disclosure.Button
                        className="text-md mb-2 flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2
                            text-left font-medium text-purple-900 hover:bg-purple-200 focus:outline-none
                            focus-visible:ring focus-visible:ring-purple-500/75 sm:py-4"
                    >
                        <span>{props.question}</span>
                        <ChevronUpIcon
                            className={`${
                                open ? "rotate-180 transform" : ""
                            } h-5 w-5 text-purple-500`}
                        />
                    </Disclosure.Button>
                    <Disclosure.Panel className="text-md mb-4 px-4 pb-2 pt-4 text-gray-500">
                        {props.answer}
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}
